import { Injectable } from '@angular/core';
import { MasterConversionInterface } from '../../core/interfaces/conversion/master-conversion.interface';
import { CurrentUserPreferenceStore } from '../../core/store/current-user-preference.store';
import { MasterListDTO } from '../dto/master.dto';
import { TableConversion } from './table.conversion';
import { UserConversion } from '../../features/organization/users/conversion/user.conversion';

@Injectable({
  providedIn: 'root',
})
export class MasterConversion implements MasterConversionInterface {
  private lang: string;

  constructor(
    private currentUserPreferenceStore: CurrentUserPreferenceStore,
    private tableConv: TableConversion,
    private userConv: UserConversion
  ) {
    this.lang = this.currentUserPreferenceStore.languageKey;
  }
  resToList(response: any[]): MasterListDTO[] {
    if (!response) {
      return [];
    }
    const result = response.map((item) => {
      return {
        id: item.id ? item.id : null,
        title: item[`title_${this.lang}`] ? item[`title_${this.lang}`] : '',
        description: item[`description_${this.lang}`]
          ? item[`description_${this.lang}`]
          : '',
        type: item.type ? item.type : '',
        color_code: item.color_code ? item.color_code : '',
        document_type: item[`document_type_title_${this.lang}`]
          ? item[`document_type_title_${this.lang}`]
          : '',
        file_name: item.file_name ? item.file_name : '',
        team_lead: item['team_lead_first_name_' + this.lang]
          ? this.userConv.resToUserMinimalIdFixedDTO(item, 'team_lead')
          : null,
        status: item.status
          ? this.tableConv.resToMasterStatus(item.status)
          : null,
        is_closed: item.is_closed ? item.is_closed : null,
        users:
          item.users && item.users.length > 0
            ? this.userConv.resToList(
                item.users.map((userItem: any) => userItem.user)
              )
            : [],
      };
    });
    return result;
  }

  resToDetails(response: any): any {
    const result: any = {};

    if (response?.id) {
      result.id = response.id;
    }

    if (response[`title_${this.lang}`]) {
      result.title = response[`title_${this.lang}`];
    }

    if (response[`description_${this.lang}`]) {
      result.description = response[`description_${this.lang}`];
    }

    if (response?.type) {
      result.type = response.type;
    }

    if (response?.color) {
      result.color = response.color;
    }

    if (response[`document_type_title_${this.lang}`]) {
      result.document_type = response[`document_type_title_${this.lang}`];
    }

    if (response?.file_name) {
      result.file_name = response.file_name;
    }

    if (response?.status) {
      result.status = this.tableConv.resToMasterStatus(response.status);
    }

    if (response?.color_code) {
      result.color_code = response.color_code;
    }

    if (response[`team_lead_first_name_${this.lang}`]) {
      result.team_lead = this.userConv.resToUserMinimalDTO(
        response,
        'team_lead'
      );
    }

    if (response?.users && response.users.length > 0) {
      result.users = this.userConv.resToList(
        response.users.map((userItem: any) => userItem.user)
      );
    }

    if (response?.created_at) {
      result.created_at = response.created_at;
    }

    if (response?.updated_at) {
      result.updated_at = response.updated_at;
    }

    if (response?.created_by) {
      result.created_by = response.created_by;
    }

    if (response?.updated_by) {
      result.updated_by = response.updated_by;
    }

    if (response?.is_finalized) {
      result.is_finalized = response.is_finalized;
    }

    return result;
  }

  resToForm(response: any): any {
    return {
      Id: response?.id,
      TitleEn: response?.title_en,
      DescriptionEn: response?.description_en,
      TitleAr: response?.title_ar,
      DescriptionAr: response?.description_ar,
      TeamLead: response?.team_lead?.id,
      Users: response?.users?.map((user: any) => user.user_id),
      DocumentType: response?.document_type?.id,
      Document: response?.document,
      Status: response?.status,
    };
  }

  formGroupToForm(formGroup: any): any {
    return {
      id: formGroup.get('Id')?.value,
      title_en: formGroup.get('TitleEn')?.value,
      title_ar: formGroup.get('TitleAr')?.value,
      description_en: formGroup.get('DescriptionEn')?.value,
      description_ar: formGroup.get('DescriptionAr')?.value,
      team_lead_id: parseInt(formGroup.get('TeamLead')?.value),
      user_ids: (formGroup.get('Users')?.value || []).map((user: string) =>
        parseInt(user, 10)
      ),
      document_type_id: formGroup.get('DocumentType')?.value,
      document: formGroup.get('Document')?.value,
      status: 'active',
    };
  }
}
